import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import Teaser, { TeaserProps } from './Teaser';
import Image from './Image';

const TeaserSplit: FC<TeaserProps> = ({
  heading,
  text,
  buttons,
  image,
  date,
  site,
  children,
  wysiwyg,
  author,
  ...rest
}) => (
  <Box {...rest}>
    <Flex
      alignItems="stretch"
      flexWrap="wrap"
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 50%', '0 0 50%']}
        bg="gray1"
        p={8}
        px={[8, 8, 8, 8, '6%']}
        borderTopLeftRadius={[0, 0, 0, 'lg']}
        borderBottomLeftRadius="lg"
        borderBottomRightRadius={['lg', 'lg', 'lg', 0]}
      >
        <Teaser
          {...{
            children,
            date,
            site,
            text,
            heading,
            wysiwyg,
            author,
            buttons:
              buttons &&
              Array.isArray(buttons) &&
              buttons?.map((btn) => ({ ...btn, size: 'large' })),
          }}
        />
      </Box>
      <Box
        flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 50%', '0 0 50%']}
        display="flex"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        justifyContent="center"
      >
        <Image
          src={image?.src}
          alt={image?.alt || heading?.title}
          width="900"
          height="600"
          noRounding
          borderTopRightRadius="lg"
          borderTopLeftRadius={['lg', 'lg', 'lg', 0]}
          borderBottomRightRadius={[0, 0, 0, 'lg']}
          containerProps={{ display: 'flex' }}
        />
      </Box>
    </Flex>
  </Box>
);

export default TeaserSplit;
